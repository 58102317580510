<template>
  <v-card class="import-wizard">
    <v-card-title class="d-flex justify-space-between align-center primary">
      <span class="text-h5 white--text">{{
        $t(`admin.productsDatabase.importWizard.steps.${currentStep}.title`)
      }}</span>
      <v-btn icon @click="confirmCloseWizard" class="white--text">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-stepper v-model="currentStepIndex" flat>
      <v-stepper-header>
        <template v-for="(step, i) in steps">
          <v-stepper-step
            :key="`step-${i}`"
            :complete="currentStepIndex > i + 1"
            :step="i + 1"
            :editable="currentStepIndex > i + 1"
          >
            {{
              $t(`admin.productsDatabase.importWizard.steps.${step}.shortTitle`)
            }}
          </v-stepper-step>

          <v-divider
            v-if="i < steps.length - 1"
            :key="`divider-${i}`"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <ProductUpload
            @product-uploaded="productInfo = $event"
          />
          
          <v-divider class="mt-4 mb-4"></v-divider>
          
          <div class="d-flex justify-end">
            <v-btn
              text
              @click="confirmCloseWizard"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!canProceedFromUpload"
              @click="handleProductUploaded"
              class="ml-2"
            >
              {{ $t('common.next') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <CoverArtUpload
            v-if="bookId"
            :book-id="bookId"
            :initial-cover-url="coverArtUrl"
            @cover-uploaded="handleCoverArtUploaded"
            @upload-error="handleUploadError"
          />

          <v-alert
            v-else-if="currentStepIndex === 2"
            type="warning"
            outlined
            class="mb-4"
          >
            {{ $t('admin.productsDatabase.importWizard.steps.coverArt.bookIdRequired') }}
          </v-alert>
          
          <v-divider class="mt-4 mb-4"></v-divider>
          
          <div class="d-flex justify-end">
            <v-btn
              text
              @click="currentStepIndex = 1"
            >
              {{ $t('common.back') }}
            </v-btn>
            <v-btn
              color="success"
              :disabled="!canProceedFromCoverArt"
              @click="finishWizard"
              class="ml-2"
            >
              {{ $t('admin.productsDatabase.importWizard.publish') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- Confirm close dialog -->
    <v-dialog v-model="showConfirmCloseDialog" max-width="400">
      <v-card>
        <v-card-title>{{
          $t("admin.productsDatabase.importWizard.confirmCloseTitle")
        }}</v-card-title>
        <v-card-text>
          {{ $t("admin.productsDatabase.importWizard.confirmClose") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showConfirmCloseDialog = false">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="error" text @click="closeWizard">
            {{ $t("common.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ProductUpload from "./ProductUpload.vue";
import CoverArtUpload from "./CoverArtUpload.vue";

export default {
  name: "ProductImportWizard",
  components: {
    ProductUpload,
    CoverArtUpload,
  },
  emits: ["close", "product-created"],
  data() {
    return {
      currentStepIndex: 1,
      steps: ["upload", "coverArt"],
      productInfo: null,
      coverArtUrl: null,
      mediaObject: null,
      showConfirmCloseDialog: false,
    };
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex - 1];
    },
    bookId() {
      return this.productInfo?.id || this.productInfo?.book?.id;
    },
    canProceedFromUpload() {
      return this.productInfo && 
        this.productInfo.encryption && 
        this.productInfo.encryption.status === 'success';
    },
    canProceedFromCoverArt() {
      return this.coverArtUrl !== null || (this.productInfo?.book?.imageUrl || this.productInfo?.book?.thumbnailUrl);
    }
  },
  methods: {
    handleProductUploaded() {
      if (this.canProceedFromUpload) {
        // Extract the initial cover art URL if available
        if (this.productInfo && this.productInfo.book) {
          this.coverArtUrl = this.productInfo.book.imageUrl || this.productInfo.book.thumbnailUrl;
        }
        
        this.currentStepIndex = 2;
      }
    },

    handleCoverArtUploaded(data) {
      this.coverArtUrl = data.coverUrl;
      this.mediaObject = data.mediaObject;

      // Update the product info with the new cover art URL
      if (this.productInfo && this.productInfo.book) {
        this.productInfo.book.imageUrl = data.coverUrl;
        this.productInfo.book.thumbnailUrl = data.coverUrl;
      }
    },

    handleUploadError(errorInfo) {
      console.error("Upload error:", errorInfo);
    },

    finishWizard() {
      // Logic to save the final product
      this.$emit("product-created", this.productInfo);
      this.$emit("close");
      this.resetWizard();
    },

    resetWizard() {
      this.currentStepIndex = 1;
      this.productInfo = null;
      this.coverArtUrl = null;
      this.mediaObject = null;
    },

    confirmCloseWizard() {
      this.showConfirmCloseDialog = true;
    },

    closeWizard() {
      this.showConfirmCloseDialog = false;
      this.resetWizard();
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.import-wizard {
  background-color: var(--v-backgroundSecondary-base);
}

.monospace-font {
  font-family: monospace;
  color: var(--v-primaryText-base);
  background-color: var(--v-lightestAccent-base);
}
</style>
