<template>
  <div>
    <h3 class="subtitle-1 font-weight-bold mb-3">{{ $t('admin.productsDatabase.importWizard.upload.title') }}</h3>
    <p class="mb-4">
      {{ $t('admin.productsDatabase.importWizard.upload.description') }}
    </p>

    <FileUpload
      :upload-url="uploadUrl"
      accept-types=".epub"
      file-types="EPUB files"
      :max-file-size="maxFileSize"
      :validate-file="validateEpubFile"
      :include-auth-token="true"
      :drag-drop-prompt="$t('admin.productsDatabase.importWizard.upload.dragDrop')"
      :uploading-message="$t('admin.productsDatabase.importWizard.upload.uploading')"
      :success-message="$t('admin.productsDatabase.importWizard.upload.success')"
      :browse-files-text="$t('admin.productsDatabase.importWizard.upload.browseFiles')"
      :upload-button-text="$t('admin.productsDatabase.importWizard.upload.uploadFile')"
      @file-selected="handleFileSelected"
      @upload-success="handleUploadSuccess"
      @upload-error="handleUploadError"
      @upload-progress="handleUploadProgress"
      @reset="resetUpload"
    />

    <!-- Upload Result Information Panel -->
    <div v-if="uploadedProductInfo" class="mt-4">
      <!-- Status Summary Alert -->
      <v-alert
        :color="uploadStatus.color"
        dense
        outlined
        class="mb-4"
      >
        <div class="font-weight-bold">{{ uploadStatus.title }}</div>
        <div>{{ uploadStatus.message }}</div>
      </v-alert>
      
      <!-- Encryption Details - Directly visible -->
      <div v-if="uploadedProductInfo.encryption" class="mb-5">
        <h3 class="subtitle-1 font-weight-bold mb-3">{{ $t('admin.productsDatabase.importWizard.upload.encryptionDetails') }}</h3>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.contentId') }}</td>
                <td>{{ uploadedProductInfo.encryption.contentId }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.originalFilename') }}</td>
                <td>{{ uploadedProductInfo.encryption.originalFilename }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.encryptedFilename') }}</td>
                <td>{{ uploadedProductInfo.encryption.encryptedFilename }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.encryptedFilePath') }}</td>
                <td>{{ uploadedProductInfo.encryption.encryptedFilePath }}</td>
              </tr>
              <tr>
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.status') }}</td>
                <td>
                  <v-chip
                    :color="uploadedProductInfo.encryption.status === 'success' ? 'success' : 'error'"
                    x-small
                    text-color="white"
                  >
                    {{ uploadedProductInfo.encryption.status }}
                  </v-chip>
                </td>
              </tr>
              <tr v-if="uploadedProductInfo.encryption.notificationError">
                <td class="font-weight-medium">{{ $t('admin.productsDatabase.importWizard.upload.notificationError') }}</td>
                <td class="error--text">{{ uploadedProductInfo.encryption.notificationError }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      
      <!-- Raw Output and Complete Response - In Expansion Panel -->
      <v-expansion-panels v-if="hasDetailedOutput">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('admin.productsDatabase.importWizard.upload.technicalDetails') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- Raw Output -->
            <div v-if="uploadedProductInfo.encryption && uploadedProductInfo.encryption.output">
              <h3 class="subtitle-1 font-weight-bold mb-2">{{ $t('admin.productsDatabase.importWizard.upload.rawOutput') }}</h3>
              <v-card outlined>
                <v-card-text class="pa-2">
                  <pre class="output-pre">{{ uploadedProductInfo.encryption.output }}</pre>
                </v-card-text>
              </v-card>
            </div>
            
            <!-- Full Response -->
            <div class="mt-4">
              <h3 class="subtitle-1 font-weight-bold mb-2">{{ $t('admin.productsDatabase.importWizard.upload.completeResponse') }}</h3>
              <v-card outlined>
                <v-card-text class="pa-2">
                  <pre class="output-pre">{{ JSON.stringify(uploadedProductInfo, null, 2) }}</pre>
                </v-card-text>
              </v-card>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue';

// Get API base URL from the environment or use a default
const apiBaseUrl = process.env.VUE_APP_API_HOST || 'https://localhost';

export default {
  name: 'ProductUpload',
  components: {
    FileUpload
  },
  emits: ['cancel', 'next', 'product-uploaded'],
  data() {
    return {
      uploadUrl: `${apiBaseUrl}/lcp/upload`,
      maxFileSize: 50 * 1024 * 1024, // 50MB
      isUploaded: false,
      hasError: false,
      selectedFile: null,
      uploadProgress: 0,
      uploadedProductInfo: null,
    };
  },
  computed: {
    // Determine if we can proceed to the next step
    canProceed() {
      if (!this.isUploaded || !this.uploadedProductInfo) {
        return false;
      }
      
      // Even with some errors (like notification errors), we might still want to allow proceeding
      if (this.uploadedProductInfo.encryption) {
        // Allow proceeding if encryption was successful, even if there was a notification error
        return this.uploadedProductInfo.encryption.status === 'success';
      }
      
      return !this.hasError;
    },
    
    // Determine the status display information
    uploadStatus() {
      if (!this.uploadedProductInfo) {
        return { 
          color: 'info',
          title: this.$t('admin.productsDatabase.importWizard.upload.status'),
          message: this.$t('admin.productsDatabase.importWizard.upload.noDataYet')
        };
      }
      
      const encryption = this.uploadedProductInfo.encryption || {};
      
      if (encryption.status === 'success') {
        if (encryption.notificationError) {
          return {
            color: 'warning',
            title: this.$t('admin.productsDatabase.importWizard.upload.partialSuccess'),
            message: this.$t('admin.productsDatabase.importWizard.upload.encryptionSuccessNotificationFailed')
          };
        } else {
          return {
            color: 'success',
            title: this.$t('admin.productsDatabase.importWizard.upload.success'),
            message: this.$t('admin.productsDatabase.importWizard.upload.encryptionAndNotificationSuccess')
          };
        }
      } else {
        return {
          color: 'error',
          title: this.$t('admin.productsDatabase.importWizard.upload.failed'),
          message: this.$t('admin.productsDatabase.importWizard.upload.encryptionFailed')
        };
      }
    },
    
    // Check if we have detailed output to show
    hasDetailedOutput() {
      return this.uploadedProductInfo && 
        ((this.uploadedProductInfo.encryption && this.uploadedProductInfo.encryption.output) || 
         Object.keys(this.uploadedProductInfo).length > 0);
    }
  },
  methods: {
    validateEpubFile(file) {
      if (!file.name.toLowerCase().endsWith('.epub') || !file.name.toLowerCase().endsWith('.lpf')) {
        return this.$t('admin.productsDatabase.importWizard.upload.errors.invalidFormat');
      }
      return true;
    },
    
    handleFileSelected(file) {
      this.selectedFile = file;
      this.isUploaded = false;
      this.hasError = false;
      this.uploadedProductInfo = null;
    },
    
    handleUploadProgress(progress) {
      this.uploadProgress = progress;
    },
    
    handleUploadSuccess(data) {
      this.isUploaded = true;
      this.uploadedProductInfo = data;
      
      // Check for partial success (encryption succeeded but LCP notification failed)
      if (data.encryption && data.encryption.status === 'success' && data.encryption.notificationError) {
        this.hasError = false; // Not treating notification error as a complete failure
        
        // Show warning notification
        this.$store.dispatch('snackbar/showSnackbar', {
          text: this.$t('admin.productsDatabase.importWizard.upload.partialSuccessNotification'),
          color: 'warning'
        });
      } else {
        this.hasError = false;
      }
      
      // Always emit product-uploaded regardless of partial success
      this.$emit('product-uploaded', data);
    },
    
    handleUploadError(errorInfo) {
      this.hasError = true;
      console.error('Upload error:', errorInfo.error);
      
      // Store the error response if available
      if (errorInfo.error && errorInfo.error.response && errorInfo.error.response.data) {
        this.uploadedProductInfo = errorInfo.error.response.data;
      }
    },
    
    resetUpload() {
      this.isUploaded = false;
      this.hasError = false;
      this.selectedFile = null;
      this.uploadProgress = 0;
      this.uploadedProductInfo = null;
    },
    
    goToNext() {
      if (this.canProceed) {
        this.$emit('next', this.uploadedProductInfo);
      }
    }
  }
};
</script>

<style scoped>
.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area-active {
  border-color: #1976d2;
  background-color: rgba(25, 118, 210, 0.05);
}

.upload-area-error {
  border-color: #ff5252;
  background-color: rgba(255, 82, 82, 0.05);
}

.upload-area-success {
  border-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.05);
}

.output-pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background: #f5f5f5;
  padding: 8px;
  font-size: 12px;
  font-family: monospace;
  max-height: 300px;
  overflow-y: auto;
}
</style>
