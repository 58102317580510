<template>
  <div>
    <!-- Drag and drop area -->
    <v-sheet
      class="upload-area d-flex flex-column align-center justify-center"
      :class="{ 
        'upload-area-active': isDragging, 
        'upload-area-error': hasError,
        'upload-area-success': isUploaded && !hasError 
      }"
      :height="height"
      @dragover.prevent="isDragging = true"
      @dragleave.prevent="isDragging = false"
      @drop.prevent="onFileDrop"
      @click="triggerFileInput"
    >
      <input
        ref="fileInput"
        type="file"
        :accept="acceptTypes"
        class="d-none"
        @change="onFileSelected"
      >
      
      <div v-if="isUploading">
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="primary"
        ></v-progress-circular>
        <div class="mt-4">{{ uploadingMessage || $t('components.fileUpload.uploading') }}</div>
        <div>{{ uploadProgress }}%</div>
      </div>
      
      <div v-else-if="isUploaded && !hasError">
        <v-icon color="success" size="64">mdi-check-circle</v-icon>
        <div class="mt-4">{{ successMessage || $t('components.fileUpload.success') }}</div>
        <div class="mt-2">{{ selectedFile ? selectedFile.name : '' }}</div>
      </div>
      
      <div v-else-if="hasError">
        <v-icon color="error" size="64">mdi-alert-circle</v-icon>
        <div class="mt-4">{{ errorMessage }}</div>
        <v-btn
          text
          color="primary"
          class="mt-4"
          @click.stop="resetUpload"
        >
          {{ $t('components.fileUpload.tryAgain') }}
        </v-btn>
      </div>
      
      <div v-else>
        <slot name="upload-icon">
          <v-icon size="64" color="grey lighten-1">mdi-cloud-upload</v-icon>
        </slot>
        <div class="mt-4">
          {{ dragDropPrompt || $t('components.fileUpload.dragDrop', { fileTypes }) }}
        </div>
        <div class="subtitle-1 mt-2 grey--text">
          {{ $t('components.fileUpload.or') }}
        </div>
        <v-btn
          color="primary"
          class="mt-4"
          @click.stop="triggerFileInput"
        >
          {{ browseFilesText || $t('components.fileUpload.browseFiles') }}
        </v-btn>
      </div>
    </v-sheet>

    <div v-if="selectedFile && !isUploading && !isUploaded" class="mt-4">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-file-document</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ selectedFile.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatFileSize(selectedFile.size) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="resetUpload">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      
      <v-btn
        v-if="showUploadButton"
        color="primary"
        block
        class="mt-4"
        :disabled="isUploading"
        @click="uploadFile"
      >
        {{ uploadButtonText || $t('components.fileUpload.uploadFile') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { AUTH_TOKEN } from '@/apollo/vue-apollo';

export default {
  name: 'FileUpload',
  
  props: {
    // URL to upload to (required)
    uploadUrl: {
      type: String,
      required: true
    },
    
    // Accepted file types (string or array)
    acceptTypes: {
      type: String,
      default: '*/*'
    },
    
    // File types displayed in the message
    fileTypes: {
      type: String,
      default: 'files'
    },
    
    // Maximum allowed file size in bytes
    maxFileSize: {
      type: Number,
      default: 50 * 1024 * 1024 // 50MB default
    },
    
    // Custom file validation function
    validateFile: {
      type: Function,
      default: null
    },
    
    // Custom messages
    uploadingMessage: String,
    successMessage: String,
    dragDropPrompt: String,
    browseFilesText: String,
    uploadButtonText: String,
    
    // Show upload button (if false, file will upload immediately after selection)
    showUploadButton: {
      type: Boolean,
      default: true
    },

    // Form data name for the file
    fileFormName: {
      type: String,
      default: 'file'
    },

    // Additional form data to send with the file
    additionalFormData: {
      type: Object,
      default: () => ({})
    },

    // Height of the upload area
    height: {
      type: [Number, String],
      default: 300
    },

    // Custom headers to be sent with the request
    customHeaders: {
      type: Object,
      default: () => ({})
    },
    
    // Whether to include auth token from localStorage (apollo-token)
    includeAuthToken: {
      type: Boolean,
      default: true
    }
  },
  
  data() {
    return {
      isDragging: false,
      isUploading: false,
      isUploaded: false,
      hasError: false,
      errorMessage: '',
      selectedFile: null,
      uploadProgress: 0,
    };
  },
  
  computed: {
    // Get auth token from localStorage
    authToken() {
      return this.includeAuthToken ? localStorage.getItem(AUTH_TOKEN) : null;
    },
    
    // Combine default headers with custom headers
    requestHeaders() {
      const headers = {
        'Content-Type': 'multipart/form-data',
        ...this.customHeaders
      };
      
      // Add authorization header if token exists
      if (this.authToken) {
        headers['Authorization'] = `Bearer ${this.authToken}`;
      }
      
      return headers;
    }
  },
  
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.validateAndSetFile(file);
        
        // Auto-upload if showUploadButton is false
        if (!this.showUploadButton && this.selectedFile && !this.hasError) {
          this.uploadFile();
        }
      }
    },
    
    onFileDrop(event) {
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      if (file) {
        this.validateAndSetFile(file);
        
        // Auto-upload if showUploadButton is false
        if (!this.showUploadButton && this.selectedFile && !this.hasError) {
          this.uploadFile();
        }
      }
    },
    
    validateAndSetFile(file) {
      // Check file size
      if (file.size > this.maxFileSize) {
        this.hasError = true;
        this.errorMessage = this.$t('components.fileUpload.errors.fileTooLarge', {
          maxSize: this.formatFileSize(this.maxFileSize)
        });
        return;
      }
      
      // Use custom validation if provided
      if (this.validateFile) {
        const validationResult = this.validateFile(file);
        if (validationResult !== true) {
          this.hasError = true;
          this.errorMessage = validationResult || this.$t('components.fileUpload.errors.invalidFile');
          return;
        }
      }
      
      this.selectedFile = file;
      this.hasError = false;
      this.errorMessage = '';
      
      // Emit file selected event
      this.$emit('file-selected', file);
    },
    
    async uploadFile() {
      if (!this.selectedFile) return;
      
      this.isUploading = true;
      this.hasError = false;
      this.errorMessage = '';
      this.uploadProgress = 0;
      
      // Create form data
      const formData = new FormData();
      formData.append(this.fileFormName, this.selectedFile);
      
      // Add additional form data if provided
      if (this.additionalFormData) {
        Object.entries(this.additionalFormData).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }
      
      try {
        const response = await axios.post(
          this.uploadUrl,
          formData,
          {
            headers: this.requestHeaders,
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.$emit('upload-progress', this.uploadProgress);
            },
          }
        );
        
        this.isUploaded = true;
        this.$emit('upload-success', response.data);
        
      } catch (error) {
        this.hasError = true;
        if (error.response) {
          // Server responded with an error status
          this.errorMessage = error.response.data.message || this.$t('components.fileUpload.errors.serverError');
        } else if (error.request) {
          // Request was made but no response received (network error)
          this.errorMessage = this.$t('components.fileUpload.errors.networkError');
        } else {
          // Something happened in setting up the request
          this.errorMessage = this.$t('components.fileUpload.errors.unexpectedError');
        }
        
        console.error('Upload error:', error);
        
        this.$emit('upload-error', {
          error,
          message: this.errorMessage
        });
      } finally {
        this.isUploading = false;
      }
    },
    
    resetUpload() {
      this.selectedFile = null;
      this.isUploading = false;
      this.isUploaded = false;
      this.hasError = false;
      this.errorMessage = '';
      this.uploadProgress = 0;
      
      // Reset file input
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
      
      this.$emit('reset');
    },
    
    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
  }
};
</script>

<style scoped>
.upload-area {
  border: 2px dashed var(--v-lightAccent-base);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-area-active {
  border-color: var(--v-primary-base);
  background-color: var(--v-primary-lighten5);
}

.upload-area-error {
  border-color: var(--v-error-base);
  background-color: var(--v-error-lighten5);
}

.upload-area-success {
  border-color: var(--v-success-base);
  background-color: var(--v-success-lighten5);
}
</style>
