<template>
  <div>
    <h3 class="subtitle-1 font-weight-bold mb-3">{{ $t('admin.productsDatabase.importWizard.steps.coverArt.title') }}</h3>
    
    <p class="mb-4">
      {{ $t('admin.productsDatabase.importWizard.steps.coverArt.description') }}
    </p>

    <v-row>
      <v-col cols="12" md="8">
        <FileUpload
          :upload-url="uploadUrl"
          accept-types="image/*"
          file-types="image files"
          :max-file-size="maxFileSize"
          :validate-file="validateImageFile"
          :include-auth-token="true"
          :drag-drop-prompt="$t('admin.productsDatabase.importWizard.steps.coverArt.dragDrop')"
          :uploading-message="$t('admin.productsDatabase.importWizard.steps.coverArt.uploading')"
          :success-message="$t('admin.productsDatabase.importWizard.steps.coverArt.success')"
          :browse-files-text="$t('admin.productsDatabase.importWizard.steps.coverArt.browseFiles')"
          :upload-button-text="$t('admin.productsDatabase.importWizard.steps.coverArt.uploadCoverArt')"
          @file-selected="handleFileSelected"
          @upload-success="handleUploadSuccess"
          @upload-error="handleUploadError"
          @upload-progress="handleUploadProgress"
          @reset="resetUpload"
        />
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <div class="cover-preview-container">
          <v-img
            v-if="coverArtUrl"
            :src="coverArtUrl"
            max-height="250"
            contain
            class="cover-preview"
          ></v-img>
          <div v-else class="cover-placeholder d-flex justify-center align-center">
            <v-icon size="48" color="secondary">mdi-image-outline</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Status information -->
    <v-alert
      v-if="isUploaded && mediaObject" 
      color="success"
      outlined
      dense
      class="mt-4"
    >
      {{ $t('admin.productsDatabase.importWizard.steps.coverArt.coverArtUploadedSuccess') }}
    </v-alert>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue';

// Get API base URL from the environment or use a default
const apiBaseUrl = process.env.VUE_APP_API_HOST || 'https://localhost';

export default {
  name: 'CoverArtUpload',
  components: {
    FileUpload
  },
  props: {
    bookId: {
      type: String,
      required: true
    },
    initialCoverUrl: {
      type: String,
      default: null
    }
  },
  emits: ['cancel', 'next', 'cover-uploaded', 'upload-error'],
  data() {
    return {
      maxFileSize: 10 * 1024 * 1024, // 10MB
      isUploaded: false,
      hasError: false,
      selectedFile: null,
      uploadProgress: 0,
      coverArtUrl: this.initialCoverUrl,
      mediaObject: null
    };
  },
  computed: {
    uploadUrl() {
      return `${apiBaseUrl}/books/${this.bookId}/cover`;
    },
    canProceed() {
      // Can proceed if either we have a cover image already uploaded
      // or we've just uploaded a new one successfully
      return this.coverArtUrl !== null && !this.hasError;
    }
  },
  methods: {
    validateImageFile(file) {
      // Check if file is an image
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(file.type)) {
        return this.$t('admin.productsDatabase.importWizard.steps.coverArt.errors.invalidImageFormat');
      }
      return true;
    },
    
    handleFileSelected(file) {
      this.selectedFile = file;
      this.isUploaded = false;
      this.hasError = false;
    },
    
    handleUploadProgress(progress) {
      this.uploadProgress = progress;
    },
    
    handleUploadSuccess(data) {
      this.isUploaded = true;
      
      // Store the media object information
      this.mediaObject = data.mediaObject;
      
      // Update the cover art preview URL
      this.coverArtUrl = data.mediaObject.contentUrl;
      
      // Emit event with the uploaded media object
      this.$emit('cover-uploaded', {
        mediaObject: this.mediaObject,
        coverUrl: this.coverArtUrl
      });
      
      // Show success notification
      this.$store.dispatch('snackbar/showSnackbar', {
        text: this.$t('admin.productsDatabase.importWizard.steps.coverArt.coverArtUploadedSuccess'),
        color: 'success'
      });
    },
    
    handleUploadError(errorInfo) {
      this.hasError = true;
      console.error('Cover art upload error:', errorInfo.error);
      
      // Emit the upload error
      this.$emit('upload-error', errorInfo);
      
      // Show error notification
      this.$store.dispatch('snackbar/showSnackbar', {
        text: errorInfo.message || this.$t('admin.productsDatabase.importWizard.steps.coverArt.coverArtUploadFailed'),
        color: 'error'
      });
    },
    
    resetUpload() {
      this.isUploaded = false;
      this.hasError = false;
      this.selectedFile = null;
      this.uploadProgress = 0;
      // We don't reset coverArtUrl here to preserve any existing image
    }
  }
};
</script>

<style scoped>
.cover-preview-container {
  width: 100%;
  border: 1px solid var(--v-lightAccent-base);
  border-radius: 4px;
  padding: 8px;
  height: 250px;
}

.cover-placeholder {
  height: 100%;
  background-color: var(--v-lightestAccent-base);
  border-radius: 4px;
}

.cover-preview {
  border-radius: 4px;
}
</style>
